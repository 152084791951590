import React, { useState, useEffect } from 'react';

import internal_fetch from "../../../util/local-api";
import Select from 'react-select';


const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '', minHeight: "0px", padding: "0px", color: "white"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
};

const StatusSelector = ({ postStatus, updatePostStatus, restrictedList }) => {
    const [allStatuses, updateAllStatuses] = useState([]);

    useEffect(() => {
        (async function iife() {
            const data = await internal_fetch.get_statuses_restricted(restrictedList);
            if (data.statuses) {
                updateAllStatuses(data.statuses.map(x => Object({ key: x.split(":")[0], value: x.split(":")[1] })));
            }
        })()
    }, [restrictedList])

    return (
        <div className="status_selector">
            <Select
                placeholder="Status..."
                value={allStatuses.map((status) => { return { label: status.value, value: status.key } }).find(e => e.value === postStatus)}
                options={allStatuses.map((status) => { return { label: status.value, value: status.key } })}
                className="basic-multi-select"
                styles={colourStyles}
                onChange={(e) => {
                    console.log(e)
                    updatePostStatus(e.value)
                }}
            />
        </div>
    );
}

export default StatusSelector;
